.WelcomeMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #447546;

  animation-name: WelcomeMessage;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

@keyframes WelcomeMessage {

  from {
    top: 0%;
  }

  to {
    top: 100%;
    display: none;
  }

}