.supplier-my-products-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &>.total-records {
    font-weight: bold;
    text-align: right;
    margin-bottom: 10px;
  }

  &>.brick-filters-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    &>.filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      margin-right: 15px;
      width: 0;

      &:last-child {
        margin-right: 0px;
      }

      &>span {
        font-weight: bold;
        text-align: right;
      }

      & select {
        margin-left: 10px;
        flex-grow: 1;
        width: 0;
        padding: 5px;
        outline: none;
        border: none;
        border: 1px solid #707070;
        text-overflow: ellipsis;
      }
    }
  }

  &>.products-list {
    background: white;
    border: 1px solid black;
    flex-grow: 1;
    height: 0;
    display: flex;
    flex-direction: column;

    &>.header {
      border-bottom: 2px solid #00000029;
      padding: 10px;
      display: flex;
      flex-direction: row;
      margin-bottom: 0px !important;

      &>.column {
        font-weight: bold;
        margin-right: 30px;

        &:last-child {
          margin-right: 0px;
        }

        &.auto {
          flex-grow: 1;
        }
      }
    }

    &>.container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 0;
      overflow-y: auto;
      padding: 10px !important;

      & .gvd-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: fit-content;
        font-size: 14px;

        &>.description {
          padding: 5px 0px;
          font-weight: bold;

          &,
          & * {
            user-select: none;
          }
        }
      }

      & .product-item {
        border: 0.5px solid #707070;
        padding: 3px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;

        &:last-child {
          margin-bottom: 0px;
        }

        &>.column {
          margin-right: 15px;

          &:last-child {
            margin-right: 0px;
          }

          &.auto {
            flex-grow: 1;
          }
        }
      }
    }
  }
}