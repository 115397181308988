.iei-container {
  margin: 0 auto;
  width: 70% !important;

  & .bba-banner {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  & > .overview {
    line-height: 1.5;
  }

  & > .title {
    line-height: 3;
    text-align: center;
  }

  & > .heading {
    line-height: 2;
    text-align: center;
    color: "#00205B";
  }

  & > div {
    margin-bottom: 20px;
  }

  & .tablestyle {
    border: 0px solid #999;
  }

  & .rowstyle {
    border: 0px solid #999;
  }

  & .cellstyle {
    border: 0px solid #999;
    padding: 15px;
  }

  & .smalltext {
    line-height: 1;
  }

  & .tablestyle {
    line-height: 1.5;
    width: 100%;
  }

  & .modal-image-container {
    margin: 0 auto;
    text-align: center;
    width: 400px;
  }

  & table.form-interest {
    & td.form-label {
      text-align: right;
      vertical-align: top;
    }
  }

  @media (max-width: 1000px) {
    & .bba-banner {
      width: 870px;
    }

    & .tablestyle.responsive tr td {
      display: block;
    }

    & .tablestyle.responsive td {
      width: 100% !important;
    }

    & table.form-interest {
      & td.cellstyle {
        padding: 5px;
      }

      & td.form-label {
        text-align: left;
      }
    }
  }

  @media (max-width: 700px) {
    & .modal-image-container {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1185px) {
    & .bba-banner {
      width: 90% !important;
    }
  }
}

@media (max-width: 600px) {
  .iei-container {
    width: 90% !important;
  }
}
