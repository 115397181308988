.SignUpPageContainer {
  background-color: #00205b;
  /*height: 100vh;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SignUpPageContainer form {
  background-color: #ffffff;
  border-radius: 1.5em;
  box-shadow: 0px 7px 10px 2px rgba(0, 0, 0, 0.35);
  padding: 30px;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.SignUpPageContainer form p {
  margin: 0;
  padding: 0;
  font-weight: bold;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.SignUpPageContainer form .FormFieldRow {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.SignUpPageContainer form .FormFieldRow input {
  min-width: 250px;
  width: 100%;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 0.3rem;
  border: none;
  border-radius: 5px;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.02);
}

.SignUpPageContainer form .FormFieldRow label {
  display: block;
  margin-bottom: 0.5rem;
}

.SignUpPageContainer form .FormFieldRow label::after {
  content: ":";
}

.SignUpPageContainer form .FormFieldRow.Error {
  font-weight: 600;
  font-size: 12px;
  color: red;
}

.SignUpPageContainer form .FormFieldRow.Success {
  font-weight: 600;
  font-size: 12px;
  color: #00205b;
}

.SignUpPageContainer form .FormButtonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.SignUpPageContainer form .FormButtonContainer button {
  flex-grow: 1;
  cursor: pointer;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  color: #fff;
  background: linear-gradient(90deg, #00205b, #abcae9);
  border: 0;
}

.SignUpPageContainer form .FormButtonContainer button:not(:last-child) {
  margin-right: 5px;
}

.SignUpPageContainer form .FormButtonContainer button:last-child {
  margin-left: 5px;
}
