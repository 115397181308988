@tailwind base;
@tailwind components;
@tailwind utilities;

.Ul_li--hover::before {
  content: "";
  background-color: #032158;
  position: absolute;
  height: 36px;
  width: 225px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  opacity: 0.1;
  left: -233px;
  transition: all 400ms ease;
}

.Ul_li--hover:hover::before {
  left: 0px;
  transition: all 300ms ease;
  z-index: -1;
}

.metismenu .has-arrow:after {
  right: 2em !important;
  opacity: 0.6 !important;
  width: 0.6em !important;
  height: 0.6em !important;
  border-width: 2px 0 0 2px !important;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

/* width */
::-webkit-scrollbar {
  width:5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* width */
select.style::-webkit-scrollbar {
  width:20px;
}

/* Track */
select.style::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
select.style::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
select.style::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* width */
.custom_scroll::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.custom_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom_scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.custom_scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.enlarged-image {
  transition: transform 0.3s ease-in-out;
}

/* Larger screens - Scale on hover */
@media only screen and (min-width: 768px) {
  .enlarged-image:hover {
    transform: scale(1.2);
  }
}

/* Smaller screens - No enlargement effect on hover */
@media only screen and (max-width: 767px) {
  .enlarged-image:hover {
    transform: scale(1);
    /* No scaling for smaller screens */
  }
}