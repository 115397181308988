.iei-container {
  margin: 0 auto;
  width: 60%;

  &>.overview {
    line-height: 2;
  }

  &> .video {
    text-align: center;
  }

  & > div {
    margin-bottom: 20px;
  }
}