.supplier-products-container {
  margin: 0 auto;

  & .supplier-products-table {
    border: 1px solid black;
    padding: 10px;
    width: 100%;

    & td {
      padding: 5px;
    }

    & th {
      padding-bottom: 10px;
    }

    & tr:nth-child(even) {
      background-color: lightgreen;
    }
  }

  & .supplier-products-table:not(:last-child) {
    margin-bottom: 20px;
  }
}