:root {
    --primary: #032158;
    --lightPrimary: rgba(3, 33, 88, 0.5);
    --hoverPrimary: #031436;
    --lightGray: #ced4da;
    --muted : #70657b; 
}

@import url(../fonts/iconsmind/iconsmind.css);

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
    width: device-width;
}

body {
    margin: 0;
    font-family: "Nunito", sans-serif;
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: #fff;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.hover\:primary:hover {
    color: var(--primary) !important;
}

.hover\:bg-lightPrimary:hover {
    background-color: var(--hoverPrimary) !important;
}

.primary {
    color: var(--primary);
}

.fill-primary {
    fill: var(--primary);
}

.border-gray {
    border-color: var(--lightGray);
}

input[type='text']:focus,input[type='password']:focus,input[type='email']:focus,textarea:focus {
    border-color: var(--hoverPrimary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 33, 88, 0.25);
}

select:focus {
    border-color: var(--hoverPrimary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 33, 88, 0.25);
}

.text-muted{
    color: var(--muted);
}