.iei-container {
  margin: 0 auto;
  width: 70% !important;

  & .bba-banner {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  & > .overview {
    line-height: 1.5;
  }

  & > .heading {
    line-height: 2;
    text-align: center;
    color: "#00205b";
  }

  & > div {
    margin-bottom: 20px;
  }

  & .tablestyle {
    border: 0px solid #999;
  }

  & .rowstyle {
    border: 0px solid #999;
  }

  & .cellstyle {
    border: 0px solid #999;
    padding: 15px;
  }

  & .smalltext {
    line-height: 1;
  }

  & .tablestyle {
    line-height: 1.5;
    width: 100%;
  }

  & .modal-image-container {
    margin: 0 auto;
    text-align: center;
    width: 400px;
  }

  & table.form-interest {
    & td.form-label {
      text-align: right;
      vertical-align: top;
    }
  }

  @media (max-width: 1000px) {
    & .bba-banner {
      width: 870px;
    }

    & .tablestyle.responsive tr td {
      display: block;
    }

    & .tablestyle.responsive td {
      width: 100% !important;
    }

    & table.form-interest {
      & td.cellstyle {
        padding: 5px;
      }

      & td.form-label {
        text-align: left;
      }
    }
  }

  @media (max-width: 700px) {
    & .modal-image-container {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1185px) {
    & .bba-banner {
      width: 90% !important;
    }
  }
}

.trms-cond-title {
  font-size: 36px;
  padding-top: 20px;
  padding-bottom: 0px;
  color: #00205b;
  text-align: left;
}

.trms-cond-heading {
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 8px;
  color: #00205b;
  text-align: center;
}

.headingLeft {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 5px;
  color: #00205b;
  text-align: left;
}

.bodyText {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: black;
  text-align: left;
}

.bodyTextPadded {
  font-size: 14px;
  padding: 15px;
  color: black;
  text-align: left;
}

.listBullet {
  line-height: 3;
  color: "#00205b";
  text-align: center;
}

.listNumber li {
  color: "green";
  text-align: left;
  margin-left: 30px;
}

@media (max-width: 600px) {
  .iei-container {
    width: 90% !important;
  }
}
