.SectionContainerRow .SectionContainer:not(:last-child) {
  border-right: 1px solid #f2f2f2;
}

.SectionContainer {
  flex-grow: 1;
  position: relative;
}

.SectionContainer .SectionContainerScroll {
  position: absolute;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
}

.SectionContainer .SectionContainerScroll h3 {
  margin: 0;
  padding: 0;
}