.BrandsContainer {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#parent_brand_inpt {
  margin-left: 0.5rem;
}

.BrandGlnSearchContainer {
  padding: 10px;
  background-color: #f2f2f2;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.BrandGlnSearchContainer #brand_gln_search {
  padding: 5px;
  margin-left: 10px;
  width: 250px
}

.BrandGlnSearchContainer #brand_gln_search:focus {
  outline: none;
}

.BrandGlnSearchContainer button {
  margin-left: 10px;
  padding: 5px;
}

.BrandsGlnSearchResult {
  flex-grow: 1;

}

.BrandsGlnSearchResult .Company {
  font-weight: bold;
}

.CreateNewBrandContainer span {
  display: block;
  margin-bottom: 10px;
}

.CreateNewBrandContainer table,
.CreateNewBrandContainer thead,
.CreateNewBrandContainer tbody,
.CreateNewBrandContainer tr,
.CreateNewBrandContainer td {
  padding: 2px 2px 2px 0px;
}

.CreateNewBrandContainer .AlreadyExists {
  margin: 0px;
  color: red;
  font-weight: bold;
  font-size: 0.8rem;
}

.BrandsSections {
  flex-grow: 1;
  margin: 5px;
  border: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
}